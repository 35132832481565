// https://github.com/michnhokn/kirby3-cookie-banner/wiki/

import { constants, instances } from '../store/index.js';

class CookieConsent {
    constructor() {
        this.dom = {};
        // this.dom.el = document.querySelector('#editCookie');
        this.dom.editTriggers = document.querySelectorAll('.edit-cookie'); 
        // this.dom.target = document.querySelector('');
        // this.dom.body = document.body;
        this.dom.body = document.querySelector('body');
        // this.dom.doc = document.documentElement; // html element

        /*
        this.state = {
            active: false
        }
        */
    }
   
    // Calling the cookie modal
    edit = (e) => {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('cookies:update', true, false);
        // document.querySelector('body').dispatchEvent(event);
        this.dom.body.dispatchEvent(event);
    }

    // intercept changes
    onSave = (e) => {
        // console.log('Saved cookie features:', event.detail);
        location.reload(); // refresh browser current url
    }

    addListeners() {
        // this.dom.el.addEventListener('click', this.edit);
        [...this.dom.editTriggers].forEach(trigger => {
            trigger.addEventListener('click', this.edit)
          });
        this.dom.body.addEventListener('cookies:saved', this.onSave); 
    }

    init() {
        
        // console.log(this.state.active); 
        this.addListeners();
    }
}


export default CookieConsent;