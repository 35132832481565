export const constants = {
  isDevice: false,
  isMobile: false,
  mainNavActive: false,
  modalActive: false
};
  
export const instances = {
  editCookies: undefined,
  hamburger: undefined,
  stickyHeader: undefined,
  modal: undefined,
  youtube: undefined,
  submenuCollapse: undefined
};
  