import { constants, instances } from '../store/index.js'; // needed for instances.modal 
// import Modal from './modal.js';

class PomYoutube {
    constructor() {
        this.dom = {};
        // this.dom.win = window;  
        // this.dom.doc = document.documentElement; // html element
        // this.dom.body = document.body;
        // this.dom.YT = null;  // API = window.YT

        this.dom.triggers = document.querySelectorAll('.youtube-play[data-vidid]'); // all triggers with vidid - can trigger modal OR inline Players by data-target and data-modal
        this.dom.targetModalPlayer = document.getElementById('playerModal'); // player in modal 
        this.dom.videoContainers = document.querySelectorAll('.video-container'); // all videocontainers (inline and modal)
        this.dom.referer = document.querySelector('meta[name="referrer"]'); // head referer (optional)
        
        this.dom.player = null; // init the current player
        // Modal
        this.dom.modalExits = document.querySelectorAll('.modal-exit'); // all Modal exit triggers (bg, button etc) -> needed for destroying the players on close 

        // Alternate Setup (think about it)
        // this setup would be needed for a more sophisticated approach storing all players and sources (see createPlaer for mor infos )
        // https://stackoverflow.com/questions/61683130/youtube-iframe-api-stopping-a-video-when-the-other-is-playing
        // this.dom.mediaElements = document.querySelectorAll('.youtube-play[data-vidid]');
        // this.dom.players = [];
        // this.dom.videoSources = []; 

        // this.state = {
            // someState: true,
            // someOtherState: null
        // } 
    }

    setReferer = () => {
        // don't send referrer infos to external services
        if (!this.dom.referer) {
            let meta = document.createElement('meta');
            meta.name = 'referrer';
            meta.content = 'no-referrer';
            document.head.appendChild(meta);
        }
    }

    videoId = (id) => {
        
        // get YouTube video id
        if (id && id.length === 11) {
            
            id = id;
        } else {
            let src = id;
            let regExp = /^.*((youtube\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            let match = src.match(regExp);
            id = (match && match[7].length == 11) ? match[7] : src;
        }
            // el.id = id;
            
        return id; 
    }

    // Generel YT Helper functions
    destroyPlayer = () => {
        
        // note you can only destroy, if YT.Player exists (so creation must have been initiated first)
        // therefore calling the destroy leads to errors if not even created yet! 
        // this.dom.player.destroy();

        // check for it first
        // if( typeof this.dom.player !== 'undefined' || this.dom.player !== null ){
        if (this.dom.player != null){
            // reset "playing attribute" on all video-containers (inline and modal)
            // note: this is not an API event. We set this attribute in order to css style
            [...this.dom.videoContainers].forEach(videoContainer => {
                videoContainer.setAttribute('data-playing', 'false');
            });  
            
            // destroy player
            this.dom.player.destroy(); // destroy the player
            this.dom.player = null; // reset
        }
    }

    // Modal Helper functions
    closeModal = (event) => {
        instances.modal.close(event); 
        // event.target.destroy();
        // this.dom.player.destroy();
        this.destroyPlayer(); 
    }

    // handle classes / attributes on player if playing (for HTML / CSS)
    getCurrentVideoContainer = (event) => {
        // sets on closest parent .video-container
        // https://stackoverflow.com/questions/22119673/find-the-closest-ancestor-element-that-has-a-specific-class
        // let currentIframe = event.target.h; // note: target.h or any other letter changes from time time within the api - its not secure to use this
        // https://stackoverflow.com/questions/7936663/find-the-parent-div-of-the-youtube-iframe-that-just-finished-playing-using-youtu
        let currentIframe = event.target.getIframe(); 
        // let currentVideoContainer = currentIframe.closest('.video-container'); 
        let currentVideoContainer = currentIframe.parentNode; // == .video-container
        // console.log(currentVideoContainer); 
        return currentVideoContainer; 
    }

    // Youtube API
    initYoutubeApi = () => {
        // init API once
        if (!document.getElementById('YouTubeApi')) {
            // load YouTube API
            var script = document.createElement('script');
            script.id = 'YouTubeApi';
            script.src = 'https://www.youtube.com/iframe_api';
            var firstScriptTag = document.getElementsByTagName('script')[0];
            document.head.appendChild(script);
            // script.addEventListener('load', this.createPlayer);
            // firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
            
        } 
    }
    onPlayerReady = (event) => {
        let videoContainer = this.getCurrentVideoContainer(event); 
        videoContainer.setAttribute('data-playing', 'true');

        event.target.playVideo();

        // closing the modal 
        if(window.YT.PlayerState.PLAYING && constants.modalActive === true) {
            // console.log('im playing in an open modal'); 
            [...this.dom.modalExits].forEach(exit => {
                exit.addEventListener('click', this.closeModal)
            });
        }
    }
    onPlayerStateChangeModal = (event) => {
        let videoContainer = this.getCurrentVideoContainer(event); 

        if (event.data == window.YT.PlayerState.ENDED) {
            videoContainer.setAttribute('data-playing', 'false');

            // stop the video 
            this.dom.player.stopVideo();
            // close the modal and destroy
            this.closeModal(event); 
        }
    }
    onPlayerStateChangeInline = (event) => {
        let videoContainer = this.getCurrentVideoContainer(event); 
       
        // destroy player if ended
        if (event.data == YT.PlayerState.ENDED) {
            videoContainer.setAttribute('data-playing', 'false');
            this.destroyPlayer(); 
        }
    }
    onPlayerError = (event) => {
        // console.log(event.data); 
    }
    // !! END Youtube API 

    // create youtube player
    createPlayer = (videoId, target) => {

        // video ID == YOUTUBE ID
        // target == 
        //  either '#modalPlayer' (div)  
        //  or 'youtube-plyr' (child div of the current media element (figure), that matches video id)

        // set language vars for player params
        // first try the html lang attribute, then the browser setting
        /* 
        * let language = window.navigator.userLanguage || window.navigator.language;
        * note that navigator.userAgent || navigator.platform || navigator.appVersion will be deprecated in chromium browsers
        * refer to console && https://web.dev/migrate-to-ua-ch/
        * so her, we only refer to our website attributes
        */
        let language = document.documentElement.lang;
        if (!language) {
            language = 'en';
        }
        let languageUI; // init param for player
        switch (language.substr(0,2)) {
            case 'de': languageUI = 'de'; break;
            case 'he': languageUI = 'he'; break;
            default: languageUI = 'en'; break;
          }

        // set up the Iframe API Object
        var plyrObj = {
            videoId: videoId,
            host: 'https://www.youtube-nocookie.com',
            playerVars:
            {
                'autoplay': 1,
                'enablejsapi': 1,
                'origin': document.domain,
                'rel': 0,
                'modestbranding': 1,
                'hl': languageUI
            },
            events: {
                'onReady': this.onPlayerReady,
                // 'onStateChange': this.onPlayerStateChange
            }
        };

        // add player events depending on target 
        // modal (id = 'playermodal') VS inline 
        if(target.id == 'playerModal') {
            // the modal
            // console.log(target.id);
            plyrObj.events['onStateChange'] = this.onPlayerStateChangeModal;
        } else {
            // what to do with regular inline player(s)
            plyrObj.events['onStateChange'] = this.onPlayerStateChangeInline;
        }
        
        // check if the player is already initiated
        if(window.YT !== undefined && window.YT.ready) {
            // it has been already called and might be playing
            // second call
            // console.log(window.YT);
            // this means that:
            // A Player is already running OR paused
            // to simplify things, we destroy player(s) and set it right after
            // if you want to make more sophisticated stuff such as -> 
            // --> pausing the currently running one instead of destroying it
            // --> you would need to have to iterate over all players and match the running on and then pausing it
            // --> also taking into account, that one could always be a modal ... 
            // --> TBD look at: https://stackoverflow.com/questions/61683130/youtube-iframe-api-stopping-a-video-when-the-other-is-playing
            
            // destroy
            this.destroyPlayer(); 
            // set up new
            this.dom.player = new window.YT.Player(target, plyrObj);
        } else {
            // initial first call - initiate the player by checking if the API exists
            // console.log('first call -> check API');
            window.onYouTubeIframeAPIReady = () => {
                this.dom.player = new window.YT.Player(target, plyrObj);
            } 
        }
    }

    // runs first
    load = (e) => {
        // console.log('yt load is fired:', e);
        e.preventDefault(); // could be the title of the media element

        // vars
        var el = e.currentTarget; // clicked element
        let target = null; // modal vs inline play

        // set header && init YT API
        this.setReferer(); // set the no-Referer Head
        this.initYoutubeApi(); // INIT the Youtube API -> run once 

        // check for video ID AND Target Element (which can be Modal or Inline)
        // get the vid id
        var videoId = this.videoId(el.dataset.vidid); // make sure the video id is youtube conform
        // get the target player (modal vs inline)
        if(el.dataset.target == 'modal') { 
            target = this.dom.targetModalPlayer; 
            // target = document.getElementById('playerModal'); // get the right modal by id #playerModal   
        } else {
            // get the media container by id (vid id is injected)
            let mediaContainer = document.getElementById('media-' + videoId);
            target = mediaContainer.querySelector('.youtube-plyr'); // find the child player element
        }

        // create the Player
        this.createPlayer(videoId, target); 
    }

    addListeners() {
        [...this.dom.triggers].forEach(trigger => {
            trigger.addEventListener('click', this.load)
        });        
    }
   

    init() {
        this.addListeners(); 
        // console.log(instances.modal);
    }
}

export default PomYoutube;