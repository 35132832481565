// Dropdown 
// currently used on: language swither
// ref link: https://www.youtube.com/watch?v=S-VeYcOCFZw

function dropdown() {
    document.addEventListener('click', e => {
        // const isDropdownButton = e.target.matches('[data-dropdown-button]'); // -> does note work
        // matches does not work when there are nested child elements within button or link 
        // https://gomakethings.com/why-the-vanilla-js-matches-method-wont-work-with-event-listeners-and-nested-links/
        
        const isDropdownButton = e.target.closest('[data-dropdown-button]');
        if(!isDropdownButton && e.target.closest('[data-dropdown]') != null) return;

        let currentDropdown;
        
        if (isDropdownButton) {
            currentDropdown = e.target.closest('[data-dropdown]');
            currentDropdown.classList.toggle('active');
        }

        document.querySelectorAll('[data-dropdown].active').forEach(dropdown => {
            // console.log(currentDropdown );
            if (dropdown === currentDropdown) return;
            // console.log('executed');
            dropdown.classList.remove('active');
        })
    })
}

export { dropdown };