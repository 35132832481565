import { constants, instances } from '../store/index.js';


class Hamburger {
    constructor() {
        this.dom = {};
        this.dom.el = document.querySelector('.hamburger');
        this.dom.target = document.querySelector('#navigation');
        this.dom.scrollLinks = document.querySelectorAll('a[data-scroll="true"]'); // scroll to links within main nav (we need to close the nav on click)
        // this.dom.body = document.body;
        this.dom.doc = document.documentElement; // html element

        this.state = {
            active: false
        }
    }

    /*
    open = (e) => {}
    close = (e) => {}
    */
    closeModals = (e) => {
        if(constants.modalActive) {
            // console.log('modal open'); 
            instances.modal.close(e);
        }
    }

    close = (e) => {
        
        this.state.active = false; 
        constants.mainNavActive = this.state.active;

        this.dom.el.classList.remove('is-active');
        this.dom.el.setAttribute('aria-expanded', 'false');

        this.dom.target.classList.remove('is-active');
        this.dom.target.setAttribute('aria-hidden', 'true');

        this.dom.doc.classList.remove('navigation-active');

    }

    toggle = (e) => {
        // console.log('hi i am clicked');

        let a = this.dom.el.getAttribute('aria-expanded');
        let b = this.dom.target.getAttribute('aria-hidden');
        let current_state; 
        
        // destroy open Modals 
        this.closeModals(e);

        // toggle classes & styles 
        this.dom.el.classList.toggle('is-active');
        this.dom.target.classList.toggle('is-active');
        this.dom.doc.classList.toggle('navigation-active');
        // this.dom.body.style.overflow = 'hidden';

        if (a == 'true') {
            a = 'false';
            b = 'true';
            current_state = false; 
            // this.state.open = false;
        } else {
            a = 'true';
            b = 'false';
            current_state = true; 
            // this.state.open = true;
        }
        // console.log(this.state.open); 
        this.dom.el.setAttribute('aria-expanded', a);
        this.dom.target.setAttribute('aria-hidden', b);
        this.state.active = current_state; 
        constants.mainNavActive = current_state;
    }

    hamburgerActive = () => {
        // e.preventDefault; 
        this.dom.el.classList.add('is-hover');
    }
    hamburgerInActive = () => {
        // e.preventDefault; 
        this.dom.el.classList.remove('is-hover');
    }

    addListeners() {
        this.dom.el.addEventListener('mouseover', this.hamburgerActive, false );
        this.dom.el.addEventListener('mouseout', this.hamburgerInActive, false );
        this.dom.el.addEventListener('click', this.toggle);
        

        /*this.dom.scrollLinks.forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                this.close(e);
            });
        });
        */ 
        [...this.dom.scrollLinks].forEach(anchor => {
            anchor.addEventListener('click', this.close)
        });
    }

    init() {
        // console.log(this.state.active); 
        this.addListeners();
    }
}


export default Hamburger;