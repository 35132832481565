// https://codepen.io/hagiang1305/pen/apxvEZ

import Swiper from 'swiper/swiper-bundle'; // '../../../node_modules/'

function swiperThumbs() {

    var galleryTop = new Swiper('.swiper-feature', {
    spaceBetween: 16,
    loop: true,
    loopedSlides: 4,
    autoplay: {
      delay: 6200,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  var galleryThumbs = new Swiper('.swiper-feature-thumbs', {
    spaceBetween: 0,
    centeredSlides: false,
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 4,
    
    // autoplay: {
      // delay: 7800,
    // },
    
  });
  
  galleryTop.controller.control = galleryThumbs;
  galleryThumbs.controller.control = galleryTop;

}

/*
function swiperVertical() {
  var swiper = new Swiper(".swiper-vertical", {
    // loop: true,
    // slidesPerView: 1,
    direction: "vertical",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}
export { swiperThumbs, swiperVertical };
*/
function swiperInterviews() {
  var swiperIv = new Swiper(".swiper-interviews", {
    // slideClass: 'card-mini', 
    slidesPerView: 1,
    // slidesPerView: 'auto',
    // centeredSlides: true,
    spaceBetween: 16,
    pagination: {
      el: ".swiper-interviews-pagination",
      clickable: true,
    },

    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      // 1216: {
      //   slidesPerView: 4,
      //   spaceBetween: 16,
      // },
    },

  });
}

function swiperImpressions() {
  var swiperIm = new Swiper(".swiper-slider-base", {
    // slideClass: 'card-mini', 
    slidesPerView: 1,
    // slidesPerView: 'auto',
    // centeredSlides: true,
    spaceBetween: 16,
    loop: true,
    // loopedSlides: 4,
    autoplay: {
      delay: 4200,
    },
    pagination: {
      el: ".swiper-slider-base-pagination",
      clickable: true,
    },

    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 48,
      },
    },

  });
}

export { swiperThumbs, swiperInterviews, swiperImpressions };