// Imports
import CookieConsent from './components/cookieConsent.js';
import Hamburger from './components/hamburger.js';
import SubmenuCollapse from './components/submenuCollapse.js';
import StickyHeader from './components/stickyHeader.js';
import Modal from './components/modal.js';
import PomYoutube from './components/youtube.js';
import { swiperThumbs, swiperInterviews, swiperImpressions } from './components/swiper.js';
import { dropdown } from './components/dropdown.js';
import { lightbox } from './components/lightbox.js';
// import { swup } from './components/swup.js';


import { constants, instances } from './store/index.js';

// Touch Device
constants.isDevice = 'ontouchstart' in window;
constants.isDevice && document.body.classList.add('is-device');
// Mobile Device (Screen Size)
constants.isMobile = window.matchMedia("only screen and (max-width: 992px)").matches;
// new: const isMobile = navigator.userAgentData.mobile;

// JS Ready
document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

// set vh css var
// document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

// Init all scripts
function app() {
    instances.editCookies = new CookieConsent();
    instances.editCookies.init();

    instances.hamburger = new Hamburger();
    instances.hamburger.init();

    instances.submenuCollapse = new SubmenuCollapse();
    instances.submenuCollapse.init();

    instances.stickyHeader = new StickyHeader();
    instances.stickyHeader.init();

    instances.modal = new Modal();
    instances.modal.init();

    instances.youtube = new PomYoutube();
    instances.youtube.init();

    lightbox();
    dropdown();
    swiperThumbs();
    swiperInterviews();
    swiperImpressions(); 
}

// SWUP Helper Function to re-initialize some scripts
function resetYoutubeInstance() {
    if (document.querySelector('.tpl-board_video')) {
        // destroy youtube instance 
        instances.youtube = null; // reset
        // console.log(instances.youtube, 'YT instance destroyed'); 
    }
}

function reloadModals() {
    // re-add the board-index listener for the modal
    var btnPrevNextIndex = document.querySelector('.prevnext-btn-index');
    btnPrevNextIndex.addEventListener('click', instances.modal.open);
  
    // on video templates init a new youtube instance and re-add the listener for opening the modal 
    if (document.querySelector('.tpl-board_video')) {
        var btnYoutubeModal = document.querySelector('.btn-media.youtube-play');

        // init Youtube again
        instances.youtube = new PomYoutube();
        instances.youtube.init();
        // add Modal listener again
        btnYoutubeModal.addEventListener('click', instances.modal.open);
    }
}

/*
// Lightbox
// This does not work, cause the import of basicLightbox is not loaded
// Instead, just reload the whole component again on contentReplaced (and not only parts of it;)
function reloadLightbox() {
    Array.from(document.querySelectorAll("[data-lightbox]")).forEach(element => {
        element.onclick = (e) => {
            console.log('hi iam clicked');
            e.preventDefault();
            // basicLightbox.create(`<img src="${element.href}">`).show();
            basicLightbox.create(`<img src="${element.href}">`).show();
        };
    });
}
*/

/*
function reloadScrollHeader() {   
    
    // if(constants.isMobile) {
    //     window.addEventListener('scroll', instances.stickyHeader.scroll); 
    // }
    
    instances.stickyHeader = null;
    instances.stickyHeader = new StickyHeader();
    instances.stickyHeader.init();
    console.log(instances.stickyHeader); 
}
*/

// Init Swup & App on swup pages || init app (default on all other pages)
if (document.querySelector('#swup')) {
    
    // Swup options
    const swupPptions = {
        linkSelector:
        'a[href^="' +
        window.location.origin +
        '"].prevnext-btn, a[href^="/"].prevnext-btn, a[href^="#"].prevnext-btn',
        plugins: [new SwupBodyClassPlugin()]
    };
    // init Swup
    const swup = new Swup(swupPptions); // only this line when included with script tag
   
    // run app once 
    app();

    // this event triggers right before the content of page is replaced
    swup.on('willReplaceContent', resetYoutubeInstance);
    // this event runs for every page view after initial load
    // swup.on('contentReplaced', reloadScrollHeader);
    swup.on('contentReplaced', reloadModals);
    swup.on('contentReplaced', lightbox);
     
} else {
  // run app once 
  app();  
}

