// https://codepen.io/rebelchris/pen/MWyyLXR


import { constants, instances } from '../store/index.js';

class Modal {
    constructor() {
        this.dom = {};
        this.dom.modals = document.querySelectorAll('.modal'); // all modals
        this.dom.modalTriggers = document.querySelectorAll("[data-modal]"); // all triggers
        this.dom.modalExits = document.querySelectorAll('.modal-exit'); // all exit triggers (bg, button etc)
        this.dom.doc = document.documentElement; // html element
        this.dom.body = document.body;
        // this.dom.win = window;  
        // this.dom.body = document.body;

        /*
        this.state = {
            stateA: 'open',
            stateB: 'close'
        }
        */
    }
   
    open = (e) => {
      // console.log('hi i modal am clicked');

      let el = e.currentTarget; // the trigger
      e.preventDefault(); 
      el.setAttribute('aria-expanded', 'true'); // set on trigger

      const modal = document.getElementById(el.dataset.modal);
      
      if(!constants.modalActive) {
        modal.classList.add('open');
        modal.setAttribute('aria-hidden', 'false');

        this.dom.doc.classList.add('modal-active');
        constants.modalActive = true; // set store

        this.closeMainNav(e);
      } else {
        // modal already open
        // close if trigger is clicked again  
        this.close(e); 
      }
    }

    // this euqals a destroy function, since it simply closes all modals and not the specific one (unlike open function)
    close = (e) => {
      // let el = e.currentTarget; // any exit element 
      // e.preventDefault(); 
      
      [...this.dom.modals].forEach(modal => {
        // console.log(modal); 
        modal.classList.remove('open');
        modal.setAttribute('aria-hidden', 'true');
      });

      // unset the trigger aria attr 
      [...this.dom.modalTriggers].forEach(trigger => {
        trigger.setAttribute('aria-expanded', 'false');
      });

      this.dom.doc.classList.remove('modal-active');
      constants.modalActive = false; // unset store
    }

    // check if main nav is active and close on open modal
    closeMainNav = (e) => {
        if(constants.mainNavActive) {
          // console.log('close MainNav');  
          instances.hamburger.close(e);
        }
    }

    addListeners() {

        [...this.dom.modalTriggers].forEach(trigger => {
          trigger.addEventListener('click', this.open)
        });
  

        [...this.dom.modalExits].forEach(exit => {
          exit.addEventListener('click', this.close)
        });

    }
 
    init() {
        this.addListeners(); 
    }
}

export default Modal;
