// https://medium.com/dailyjs/mimicking-bootstraps-collapse-with-vanilla-javascript-b3bb389040e7
// https://codepen.io/datchley/pen/Jvdryy

import { constants, instances } from '../store/index.js';

class SubmenuCollapse {
    constructor() {
        this.dom = {};
        this.dom.win = window;  
        this.dom.triggers = Array.from(document.querySelectorAll('[data-toggle="submenu"]'));
        // this.dom.triggers = document.querySelectorAll('[data-toggle="submenu"]');
        document.querySelectorAll("[data-modal]"); 
        // this.dom.slides = document.getElementsByClassName('.simple-slide');

        this.fnmap = {
            'toggle': 'toggle',
            'show': 'add',
            'hide': 'remove'
        };
        
    }
   
    collapse = (selector, cmd) => {
    // const collapse = (selector, cmd) => {
        const targets = Array.from(document.querySelectorAll(selector));
        targets.forEach(target => {
            target.classList[this.fnmap[cmd]]('show');
        });
    }
    addListeners() {
        this.dom.win.addEventListener('click', (ev) => {
            // const elm = ev.target;
            const elm = ev.target.closest('.btn-collapse'); // get closest parent or element that is the button -> we need this, cause the click happens on inside labels and svg elements as well (target is a wrapped icon in button)
            // console.log(this.dom.triggers); 

            if (this.dom.triggers.includes(elm)) {
                 
                // active state button 
                // remove active class from all triggers collapse butttons
                /*
                [...this.dom.triggers].forEach(trigger => {
                    trigger.classList.remove('is-active');
                });
                */
                // active state button 
                if (elm.classList.contains('is-active')) {
                    elm.classList.remove('is-active'); // already open? then remove on click
                } else {
                    elm.classList.add('is-active'); // add active
                }
                

                const selector = elm.getAttribute('data-target');
                // console.log(selector); 
                this.collapse(selector, 'toggle');
            }
        }, false);
    }
    init() {
        if(this.dom.triggers !== undefined) {
            this.addListeners();
        }
        // if(constants.isMobile) {}
    }
}

export default SubmenuCollapse;