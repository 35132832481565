// BasicLightbox JS
// Demo: https://codepen.io/electerious/pen/rLBvGz
// Page: https://basiclightbox.electerious.com/

import * as basicLightbox from 'basiclightbox';

// Lightbox
function lightbox() {
    
    Array.from(document.querySelectorAll('[data-lightbox]')).forEach(element => {
        element.onclick = (e) => {

            e.preventDefault();

            // vars
            const appMode = document.querySelector('.body-app'); // check for app mode pages on body
            var html = null;

            if (appMode != null) {

                // get text nodes for display in lightbox
                // var caption = e.target.nextElementSibling.innerText; // .innerText
                const p = e.target.parentNode; // parent
                var caption = null,
                    copy = null,
                    figcaption = null,
                    captionEl = p.querySelector('.caption'),
                    copyEl = p.querySelector('.copy-text'),
                    copySVG = '<div class="copy-sign"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M9.152 50.321C9.158 27.014 28.047 8.117 51.354 8.117c23.31 0 42.198 18.898 42.198 42.204 0 23.31-18.889 42.204-42.198 42.208-23.307-.004-42.196-18.896-42.202-42.208zm7.338 0c.042 19.255 15.613 34.831 34.864 34.868 19.253-.037 34.827-15.613 34.86-34.868-.033-19.251-15.607-34.829-34.86-34.864-19.25.036-34.822 15.613-34.864 34.864z"></path><path d="M51.351 72.325a21.956 21.956 0 01-15.556-6.445 21.946 21.946 0 01-6.446-15.539 21.95 21.95 0 016.45-15.574 21.902 21.902 0 0115.552-6.444 21.934 21.934 0 0115.562 6.444l-5.162 5.166a14.648 14.648 0 00-10.4-4.307 14.64 14.64 0 00-10.393 4.307c-2.877 2.875-4.299 6.621-4.303 10.408a14.623 14.623 0 004.309 10.379 14.632 14.632 0 0010.387 4.301 14.633 14.633 0 0010.393-4.305l.008.004 5.16 5.16a21.946 21.946 0 01-15.547 6.445h-.014z"></path></svg></div>';

                // capture text 
                if (captionEl != null) {
                    caption = captionEl.innerText; // .innerText
                }
                if (copyEl != null) {
                    copy = copyEl.innerText; // .innerText
                }

                // set figcaption
                if (caption != null && copy != null) {
                    figcaption = caption + '<br>' + copySVG + ' ' + copy;
                } else if (caption != null) {
                    figcaption = caption; 
                } else if (copy != null) {
                    figcaption = copySVG + ' ' + copy; 
                } else {
                    figcaption = figcaption; // null
                }
            
                // console.log(caption + ' ' + copy ); 
                // build html 
                if (figcaption != null) {
                    html = `<div class="customLightbox-modal"><div class="figure"><img src="${element.href}"><div class="figcaption">${figcaption}</div></div></div>`;
                } else {
                    html = `<img src="${element.href}">`;
                }
                
            } else {
                 // build html 
                html = `<img src="${element.href}">`;
            }

            // create instance
            // basicLightbox.create(`<img src="${element.href}">`).show();
            const instance = basicLightbox.create(html, { className: 'customLightbox' });

            instance.show();

            if(instance.visible()) {
                // close insance on boards when prevnext btns are clicked
                let prevNextTriggers = document.querySelectorAll(".prevnext-btn");
                if (prevNextTriggers !== null) {
                    Array.from(prevNextTriggers).forEach(element => {
                        element.onclick = (e) => {
                            instance.close();
                        };
                    });
                } 
            }

        };
    });
    
}

export { lightbox };
