import { constants, instances } from '../store/index.js';

class StickyHeader {
    constructor() {
        this.dom = {};
        this.dom.win = window;  
        this.dom.body = document.body;
        
        this.state = {
            scrollUp: 'scroll-up',
            scrollDown: 'scroll-down',
            lastScroll: 0
        }
    }

    scroll = () => {
        const currentScroll = this.dom.win.pageYOffset;
        if (currentScroll <= 0) {
            this.dom.body.classList.remove(this.state.scrollUp);
            return;
        }
        if (currentScroll > this.state.lastScroll && !this.dom.body.classList.contains(this.state.scrollDown)) {
            // down
            this.dom.body.classList.remove(this.state.scrollUp);
            this.dom.body.classList.add(this.state.scrollDown);
            // lottiePlayer.play();
        } else if (
            currentScroll < this.state.lastScroll &&
            this.dom.body.classList.contains(this.state.scrollDown)
        ) {
            // up
            this.dom.body.classList.remove(this.state.scrollDown);
            this.dom.body.classList.add(this.state.scrollUp);
            // lottiePlayer.stop();
        }
        this.state.lastScroll = currentScroll;
    }

    addListeners() {
        this.dom.win.addEventListener('scroll', this.scroll);  
    }

    init() {
        if(constants.isMobile) {
            this.addListeners();
        }
    }
}

export default StickyHeader;

